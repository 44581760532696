import React from "react";
import logoWhite from "./../../podle-logo.svg";
import logoBlack from "./../../podle-logo-black.svg";

import "./Home.css";

export default function Home(props) {
  let logoClass = "Home-logo ";
  let logo = logoWhite;
  let theme = "";
  let textClass = "";
  if (props.animateLogo === true) {
    logoClass += " Home-logo-animated";
  }
  if (props.theme === "white"){
    logo = logoBlack;
    theme = props.theme;
    textClass="black-text";
  }
  return (
    <div className={"h-100 w-100 " + theme}>
      <div className="container p-O">
        <header className={"Home-header " + theme}>
          <img src={logo} className={logoClass} alt="logo" />
          <a
            className={"Home-link " + theme}
            href="https://podle.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            Podle
          </a>
          <div  className={textClass}>
            {props.children}
          </div>
        </header>
      </div>
    </div>
  );
}
