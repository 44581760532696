import React from "react";
import PropTypes from "prop-types";
import plyr from "plyr";
import "./plyr.css";

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { src: this.props.src };
  }
  componentDidMount() {
    this.player = new plyr("audio");
  }

  componentWillUnmount() {
    this.player.destroy();
  }

  render() {
    return (
      <>
        <audio preload="none"  >
          <source src={this.state.src} type="audio/mp3"></source>
        </audio>
      </>
    );
  }
}

AudioPlayer.defaultProps = {
  options: {
    controls: [
      "rewind",
      "play",
      "fast-forward",
      "progress",
      "current-time",
      "duration",
      "mute",
      "volume",
      "settings",
      "fullscreen",
    ],
    i18n: {
      restart: "Restart",
      rewind: "Rewind {seektime}s",
      play: "Play",
      pause: "Pause",
      fastForward: "Forward {seektime}s",
      seek: "Seek",
      seekLabel: "{currentTime} of {duration}",
      played: "Played",
      buffered: "Buffered",
      currentTime: "Current time",
      duration: "Duration",
      volume: "Volume",
      mute: "Mute",
      unmute: "Unmute",
      enableCaptions: "Enable captions",
      disableCaptions: "Disable captions",
      download: "Download",
      enterFullscreen: "Enter fullscreen",
      exitFullscreen: "Exit fullscreen",
      frameTitle: "Player for {title}",
      captions: "Captions",
      settings: "Settings",
      menuBack: "Go back to previous menu",
      speed: "Speed",
      normal: "Normal",
      quality: "Quality",
      loop: "Loop",
    },
  },
};

AudioPlayer.propTypes = {
  options: PropTypes.object,
  sources: PropTypes.object,
  source: PropTypes.func,
  destroy: PropTypes.func,
};

export default AudioPlayer;
