import React, { Component } from "react";
import { withRouter } from "react-router";

import "./PlaylistPlayer.css";
import logo from "./../../podle-logo-black.svg";

class PlaylistPlayer extends Component {
  state = {
    
  };

  componentDidMount() {
    this.appendPlayer(`https://files.podle.net/player_config/bertrand/${this.getFolderId()}/${this.getConfigId()}.js`);
  }

  appendPlayer = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    script.onload = function(){
    }.bind(this);

    document.body.appendChild(script);
  }

  getFolderId(){
    return this.props.match.params.folderId;
  }

  getConfigId(){
    return this.props.match.params.configId;
  }

  doRender = () => {
    return (
      <div className="h-100 w-100 white">
        <div className="container h-100 d-flex align-items-center ">
          <div className="position-absolute logo pull-right">
            <a
              className="Home-link"
              href="https://podle.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo}
                alt="logo"
                className="img-fluid"
                height="128"
                width="128"
              />
            </a>
          </div>
          <a
            className="Home-link black-text"
            href="https://podle.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="position-absolute black-footer h4">by Podle.</div>
            <div className="position-absolute black-copyright">
              Podle © {new Date().getFullYear()}
            </div>
          </a>
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto player mt-5">
                <div className="border-round">
                <div id="podle-player-container"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.doRender();
  }
}
export default withRouter(PlaylistPlayer);
