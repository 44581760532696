import React, { Component } from "react";
import { withRouter } from "react-router";

import Home from "../home/Home.jsx";
import "./PodlePlayer.css";
import logo from "./../../podle-logo-black.svg";

class PodlePlayer extends Component {
  state = {
    url: null,
    errorMessage: "Loading",
    animateLogo: true
  };

  componentDidMount() {
    if (this.props.mediaSrc !== undefined && this.props.mediaSrc !== null) {
      this.setState({
        url: this.props.mediaSrc,
        coverUrl: this.props.coverUrl,
      });
    } else {
      this.getData();
    }
    this.appendPlayer(process.env.REACT_APP_PODLE_PLAYER_URL);
  }

  appendPlayer = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    script.onload = function(){
      window["PodlePlayer"].init({
        apikey : process.env.REACT_APP_DEMO_USER_API_KEY,
        siteId : process.env.REACT_APP_DEMO_USER_SITE_ID,
        project : this.getProject(),
        productionId: this.getProductionId(),
        showTitle : true,
        title: "Listen to this article",
        style :{
          secondaryColor:"#000000"
        }
      });
    }.bind(this);
    document.body.appendChild(script);
  }

  getProject = () => {
    return this.props.match.params.project;
  };

  getProductionId = () => {
    return this.props.match.params.productionId;
  };

  getQueryUrl = () => {
    return `${process.env.REACT_APP_API_GET_PRODUCTION_INFO}/${this.getProject()}/${this.getProductionId()}`;
  };

  getData = (file) => {
    fetch(this.getQueryUrl(), {
      headers: {
        "Authorization" : `Bearer ${process.env.REACT_APP_DEMO_USER_API_KEY}`,
        "x-podle-client" : 'player-webapp',
        "Content-type" : 'application/json;charset=UTF-8',
      },
    })
    .then((data) => data.json())
    .then((data) => {
      if (Object.keys(data).length === 0){
        this.setState({
          errorMessage: "An error occured retrieving the media",
          animateLogo: false,
        });
      } else {
        this.setState({ url: data.url, coverUrl : data.urlMainImage, title : data.title });
      }
    })
    .catch((error) => {
      this.setState({
        errorMessage: "An error occured retrieving the media",
        animateLogo: false,
      });
    });
  };

  getDefaultRendering = () => {
    return (
      <Home animateLogo={this.state.animateLogo} theme="white">
        <p>{this.state.errorMessage}</p>
      </Home>
    );
  };

  doRender = (coverUrl, title) => {
    return (
      <div className="h-100 w-100 white">
        <div className="container h-100 d-flex align-items-center ">
          <div className="position-absolute logo pull-right">
            <a
              className="Home-link"
              href="https://podle.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo}
                alt="logo"
                className="img-fluid"
                height="128"
                width="128"
              />
            </a>
          </div>
          <a
            className="Home-link black-text"
            href="https://podle.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="position-absolute black-footer h4">by Podle.</div>
            <div className="position-absolute black-copyright">
              Podle © {new Date().getFullYear()}
            </div>
          </a>
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto text-center">
                <h3 className="font-weight-bold article-title">{title}</h3>
              </div>
              <div className="col-md-6 mx-auto light-shadow mt-5 p-0">
                <img
                  className="img-fluid "
                  src={coverUrl}
                  alt="poster"
                />
              </div>
              <div className="col-md-10 mx-auto player mt-5">
                <div className="border-round">
                <div id="podle-player-container"></div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {url, coverUrl, title } = this.state;
    window.document.title = `Podle | ${title}`;
    if (url == null) {
      return this.getDefaultRendering();
    } else {
      return this.doRender(coverUrl, title);
    }
  }
}

export default withRouter(PodlePlayer);
