import React, { Component } from "react";
import { withRouter } from "react-router";

import Home from "../home/Home.jsx";
import AudioPlayer from "../audioPlayer/AudioPlayer.jsx";
import "./Player.css";

import logo from "./../../podle-logo.svg";

const API_CLIENT_TERM = "client";
const API_NEWSLETTER_TERM = "newsletterName";
const API_DATE_TERM = "date";

class Player extends Component {
  state = {
    url: null,
    errorMessage: "Loading",
    animateLogo: true
  };

  componentDidMount() {
    if (this.props.mediaSrc !== undefined && this.props.mediaSrc !== null) {
      this.setState({
        url: this.props.mediaSrc,
        coverUrl: this.props.coverUrl,
      });
    } else {
      this.getData();
    }
  }

  getClient = () => {
    return this.props.match.params.client;
  };

  getQueryUrl = () => {
    var defaultUrl = `${process.env.REACT_APP_PLAYER_BACKEND}?${API_CLIENT_TERM}=${this.getClient()}&${API_NEWSLETTER_TERM}=${this.props.match.params.newsletterName}`;
    if (this.props.match.params.publicationDate != null) {
        return `${defaultUrl}&${API_DATE_TERM}=${this.props.match.params.publicationDate}`;
    }
    return defaultUrl;
  };

  getPodcastUrl = (publicationDate) => {
    return `${process.env.REACT_APP_PODLE_API_STREAM_BACKEND}/${this.props.match.params.client}/${this.props.match.params.newsletterName}/${publicationDate}/newsletter.mp3`;
  }

  getData = (file) => {
    fetch(this.getQueryUrl(), {
      headers: {
        "x-api-key": process.env.REACT_APP_PLAYER_BACKEND_API_KEY,
      },
    })
    .then((data) => data.json())
    .then((data) => {
      this.setState({ url: this.getPodcastUrl(data.date), coverUrl : data.coverUrl });
    })
    .catch((error) => {
      this.setState({
        errorMessage: "An error occured retrieving the media",
        animateLogo: false,
      });
    });
  };

  getDefaultRendering = () => {
    return (
      <Home animateLogo={this.state.animateLogo}>
        <p>{this.state.errorMessage}</p>
      </Home>
    );
  };

  doRender = (url, coverUrl) => {
    return (
      <div className="container h-100 d-flex align-items-center ">
        <div className="position-absolute logo pull-right">
          <a
            className="Home-link"
            href="https://podle.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              alt="logo"
              className="img-fluid"
              height="128"
              width="128"
            />
          </a>
        </div>
        <a
          className="Home-link"
          href="https://podle.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="position-absolute footer h4">by Podle.</div>
          <div className="position-absolute copyright">
            Podle © {new Date().getFullYear()}
          </div>
        </a>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto shadow-lg mt-5">
              <img
                className="img-fluid img-thumbnail"
                src={coverUrl}
                alt="poster"
              />
            </div>
            <div className="col-md-10 mx-auto player mt-5">
              <div className="border-round">
                <AudioPlayer src={url}></AudioPlayer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { url, coverUrl } = this.state;
    if (url == null) {
      return this.getDefaultRendering();
    } else {
      return this.doRender(url, coverUrl);
    }
  }
}

export default withRouter(Player);
